import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

import Form from "react-bootstrap/Form";

export const PressPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content;
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const handleSuccess = () => setSuccess(true);
  const handleFailure = () => setFailure(true);

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);

    if (form.checkValidity()) {
      const name = form.elements.name.value;
      const email = form.elements.email.value;
      const phone = form.elements.phone.value;
      const org = form.elements.org.value;
      const subject = form.elements.subject.value;
      const enquiry = form.elements.enquiry.value;

      const qs = `name=${name}&email=${email}&phone=${phone}&org=${org}&subject=${subject}&enquiry=${enquiry}`;
      fetch(`/.netlify/functions/press?${qs}`)
        .then(handleSuccess)
        .catch(handleFailure);
    }
  };
  return (
    <>
      <div className="row pt-5">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <PageContent
            className="content pt-2 pb-4 text-line-height"
            content={content}
          />
          {success && (
            <div>
              <h2>Success!</h2>
              <h3>Thanks for getting in touch, someone will respond asap</h3>
            </div>
          )}
          {failure && (
            <div>
              <h2>Oops!</h2>
              <h3>
                There was a problem with submitting the form. Please email{" "}
                <a
                  href="mailto:labouragainstas@outlook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  labouragainstas@outlook.com
                </a>
              </h3>
            </div>
          )}
          {!success && !failure && (
            <Form
              className="needs-validation py-4"
              validated={validated}
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="form-row">
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="nameInput">
                    Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameInput"
                    placeholder="Name"
                    required
                    name="name"
                  />
                </div>
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="emailInput">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    placeholder="Email address"
                    required
                    name="email"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="phoneInput">
                    Phone number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneInput"
                    placeholder="Phone number"
                    name="phone"
                  />
                </div>
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="orgInput">
                    Organisation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="orgInput"
                    placeholder="Organisation"
                    name="org"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="mb-0 pt-3" htmlFor="subjectInput">
                  Subject*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Subject"
                  required
                  name="subject"
                />
              </div>
              <div className="form-group">
                <label className="mb-0 pt-3" htmlFor="enquiryInput">
                  Enquiry
                </label>
                <textarea
                  className="form-control"
                  id="enquiryInput"
                  rows="3"
                  required
                  name="enquiry"
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-primary laas-bg-dr border-0"
              >
                Send your message
              </button>
            </Form>
          )}
        </div>
      </div>
      <ThreeLinkBlock />
    </>
  );
};

const PressPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PressPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

export default PressPage;

export const pressPageQuery = graphql`
  query PressPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
